.index-intro-header {
  background: radial-gradient(ellipse farthest-side at 100% 100%, #a1c8d6 10%, #66a9bd 50%, #085078 120%);

  .container {
    text-align: center;
  }

  h1 {
    margin-top: 180px;
    margin-bottom: 25px;
    color: #fff;
    line-height: 44px;
    font-size: 37px; 
    font-weight: 100;
    text-shadow: 0 1px 0 rgba(0,91,103,.3);
    font-family: $franklin;

    @media(max-width: 991px) {
      font-size: 28px;
      margin-top: 140px;
    }

    @media(max-width: 767px) {
      font-size: 25px;
      margin-top: 120px;
      line-height: 37px;
    }
  }

  .version {
    display: inline-block;
    padding: 4px 10px;
    text-shadow: 0 1px 0 rgba(25,53,71,.12);
    background: rgba(3, 52, 78, 0.1);
    border: 1px solid rgba(25,53,71,.04);
    border-top-color: rgba(25,53,71,.08);
    box-shadow: 0 1px 0 rgba(255,255,255,.1);
    color: #fff;
    border-radius: 3px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }

  p {
    font-size: 17px;
    color: #f4f6ff;
    line-height: 27px;
    margin: 30px auto 65px;
    text-shadow: 0 1px 0 rgba(0,91,103,.3);
    width: 90%;

    @media(max-width: 991px) {
      width: 100%;
      font-size: 15px;
    }

    a {
      color: #fff;
      font-weight: 500;
    }
  }
}

.index-intro-demos {
  margin-top: 100px;

  @media(max-width: 767px) {
    margin-top: 70px;
  }

  .demo {
    background: #f7f7f7;
    padding-top: 50px;
    margin-bottom: 60px;

    @media(max-width: 991px) {
      padding-bottom: 30px;
    }

    @media(max-width: 767px) {
      padding-top: 30px;
      text-align: center;
    }

    &.demo--left {
      .design {
        position: relative;
        left: -15px;
      }
    }

    &.demo--right {
      .design {
        float: right;
        position: relative;
        left: 15px;
      }

      .col-sm-7 {
        @media(min-width: 768px) {
          padding-left: 55px;
        }
      }
    }

    .design {
      @media(max-width: 767px) {
        display: none;
      }
    }

    h3 {
      color: #2b2d3c;

      @media(max-width: 991px) {
        margin-top: 0;
      }
    }

    p {
      margin-top: 20px;
      line-height: 25px;
      color: #5a718a;
      font-size: 15px;
    }

    .btn-shadow {
      margin-top: 20px;
    }
  }
}

.index-intro-features {
  margin-top: 70px;
  text-align: center;

  @media(max-width: 767px) {
    margin-top: 40px;
  }

  i {
    font-size: 45px;
    height: 60px;
    color: #869bb7;

    &.icon-tools-2 {
      margin-bottom: 9px;
    }

    &.ion-ios-infinite-outline {
      font-size: 50px;
      position: relative;
      top: -7px;
    }
  }

  p {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }
}

.index-intro-cta {
  margin-top: 80px;
  text-align: center;

  .btn-shadow {
    box-shadow: rgba(23,43,99,.35) 0 7px 28px;
  }
}

.index-intro-footer {
  text-align: center;
  margin-top: 110px;
  margin-bottom: 80px;

  a {
    display: inline-block;
    margin: 0 10px;
  }

  p {
    margin-top: 15px;
    color: #656565;
  }
}
