.agency-hero {
  min-height: 100vh;
  position: relative;
  background-image: url('../images/graphics/hero-01.png');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.17);
  }

  section {
    position: relative;
    z-index: 9;
    height: 100vh;
  }

  .hero-text {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 130px);
    text-align: center;

    @media(max-width: 767px) {
      top: calc(50% - 190px);
    }
  }

  h1 {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include animationDelay(.1s);
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
    margin-top: 17px;
    width: 80%;
    margin: 30px auto;

    @include animationDelay(.4s);

    @media(max-width: 991px) {
      font-size: 30px;
      width: 90%;
    }

    @media(max-width: 767px) {
      width: 100%;
      font-size: 28px;
      line-height: 38px;
    }
  }

  a {
    display: inline-block;
    margin: 0 auto;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 1px;
    border: 1px solid #fff;
    padding: 11px 22px;
    border-radius: 63px;
    
    @include transition(.12s linear);
    @include animationDelay(.4s);

    &:hover {
      background: #fff;
      color: #222;
      text-decoration: none;
    }
  }
}

.agency-intro {
  margin-top: 90px;

  h3 {
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
  }

  hr {
    width: 130px;
    margin: 35px auto;
    border-top: 2px solid #E4E4E4;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #454545;
    width: 65%;
    margin: auto;
    line-height: 29px;

    @media(max-width: 767px) {
      width: auto;
    }
  }
}

.agency-portfolio {
  padding-top: 60px;
  padding-bottom: 100px;
  background: #F9F8F8;
  margin-top: 120px;

  @media(max-width: 767px) {
    padding-bottom: 60px;
  }

  h2 {
    text-align: center;
    margin-bottom: 70px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .project {
    width: 100%;
    margin-right: 35px;
    margin-bottom: 35px;
    overflow: hidden;
    display: block;
    position: relative;

    &:hover {
      .mask {
        opacity: 1;

        .info {
          @include transform(none);
        }

        .btn-see-project {
          @include transform(none); 
        }
      }

      .pic {
        @include transform(scale3d(1.1, 1.1, 1.1));
      }
    }

    .mask {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(68, 101, 144, 0.9);
      opacity: 0;
      z-index: 9;
      color: #fff;
      text-align: center;

      @include transition(all .35s ease);

      .info {
        display: block;
        @include transform(translateY(-8px));
        @include transition(all .35s ease);
      }

      h3 {
        margin-top: 70px;
        font-size: 20px;
        font-weight: 500;
      }

      p {
        color: #BFCEEC;
        font-size: 15px;
        font-weight: 500;
      }

      .btn-see-project {
        display: inline-block;
        margin-top: 30px;
        border: 1px solid #fff;
        padding: 9px 16px;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.8px;

        @include transform(translateY(8px));
        @include transition(all .35s ease);
      }
    }

    .pic {
      display: block;
      background-size: cover;
      background-position: center center;
      height: 280px;
      width: 100%;
      float: left;

      @include transition(all .35s ease);
    }
  }
}

.agency-testimonial {
  margin-top: 120px;

  .quote {
    text-align: center;
    width: 82%;
    margin: 0 auto;
    font-size: 22px;
    position: relative;
    font-weight: 300;
    line-height: 40px;
    color: #2381B8;

    @media(max-width: 767px) {
      width: 90%;
      font-size: 20px;
      line-height: 33px;
    }

    .quote-mark {
      font-family: $georgia;
      font-size: 59px;
      position: absolute;
      top: 9px;
      color: #55a2ce;
      left: -25px;
    }
  }

  .author {
    text-align: center;
    margin-top: 30px;

    img {
      border-radius: 50%;
      max-width: 65px;
    }

    .name {
      display: inline-block;
      margin-left: 15px;
      font-size: 16px;
      color: #555;
      position: relative;
      top: 12px;

      .company {
        display: block;
        text-align: left;
        font-size: 13px;
        margin-top: 3px;
        color: #777;
      }
    }
  }
}

.agency-from-the-blog {
  margin-top: 120px;
  border-top: 1px solid #ececec;
  padding-top: 60px;

  h3 {
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .post {
    @media(max-width: 767px) {
      margin-bottom: 40px;
    }

    .pic {
      display: block;
      text-decoration: none;
      height: 200px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      position: relative;

      &:hover {
        @include transform(scale3d(1.04, 1.04, 1.04));
      }

      &:after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: rgba(51, 51, 51, 0.3);
        opacity: 0;

        @include transition(all .2s ease);
      }
    }

    .title {
      margin-top: 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
    }

    .min-read {
      font-size: 13px;
      color: #666;
      margin-top: 5px;
    }

    p {
      margin-top: 20px;
      line-height: 25px;
      color: #666;
      font-size: 14px;
    }
  }
}

.agency-footer {
  margin-top: 200px;
  padding: 70px 0 90px;
  background: #353D4D;
  position: relative;

  @media(max-width: 767px) {
    margin-top: 130px;
    padding-bottom: 60px;
    text-align: center;
  }

  .col-sm-3 {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }

  .go-top {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -25px;
    width: 50px;
    height: 50px;
    font-size: 12px;
    letter-spacing: 1px;
    background: #fff;
    padding-left: 2px;
    padding-top: 8px;
    border-radius: 50%;
    text-align: center;
    color: #222;
    text-decoration: none;

    i {
      display: block;
      font-size: 16px;
    }
  }

  h4 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 3px;
    font-family: $lato;
    font-size: 16px;
  }

  a.legal {
    color: #AFB9CB;
    display: block;
    margin-top: 35px;
    font-size: 14px;
    font-family: $merriweather;
    line-height: 28px;

    & + .legal {
      margin-top: 0px;
    }
  }

  p {
    color: #AFB9CB;
    margin-top: 35px;
    font-size: 14px;
    font-family: $merriweather;
    line-height: 28px;
  }

  a.social {
    width: 30px;
    height: 30px;
    background: #BABFCD;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-right: 12px;

    @include transition(all .2s);

    &:hover {
      background: #fff;
    }

    &.first {
      margin-top: 20px;
    }

    i {
      position: absolute;
      color: #3F4552;
      font-size: 19px;

      &.fa-facebook {
        top: 7px;
        left: 10px;
      }

      &.fa-twitter {
        top: 7px;
        left: 6px;
      }

      &.fa-dribbble {
        top: 6px;
        left: 7px;
      }

      &.fa-github {
        top: 6px;
        left: 7px;
      }
    }
  }
}
