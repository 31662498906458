.theme-docs-header {
  min-height: 340px;
  // background-image: linear-gradient(-45deg, #535f8e 0%, #515e90 20%, #0c1429 100%);
  background-image: linear-gradient(310deg,#272B53 0,#4f7296 90%);

  @media(max-width: 767px) {
    min-height: 330px;
  }

  section {
    padding-top: 150px;

    @media(max-width: 991px) {
      padding-top: 130px;
    }

    @media(max-width: 767px) {
      padding-top: 100px;
      text-align: center;
    }

    h1 {
      color: #fff;
      line-height: 44px;
      font-size: 35px;
      font-weight: 400;
      font-family: $franklin;

      @media(max-width: 991px) {
        font-size: 30px;
      }

      @media(max-width: 767px) {
        font-size: 27px;
      }
    }

    p {
      color: #cbcfe4;
      margin-top: 20px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.nav-sidebar {
  margin-top: 40px;
  padding-left: 10px;

  &.fixed {
    position: fixed;
    top: 0;
  }

  .nav {
    li {
      &.active {
        > ul {
          display: block;
        }

        > a {
          color: #724dc5;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 70%;
            top: 5px;
            width: 1px;
            background-color: #724dc5;
          }
        }
      }

      > ul {
        padding-left: 17px;
        list-style-type: none;
        display: none;

        a {
          display: block;
          padding: 3px 20px;
        }
      }
    }

    a {
      padding: 5px 15px;
      color: #444;
      position: relative;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.theme-docs-wrapper {
  margin-top: 20px;
  margin-bottom: 100px;

  .docs-body {
    h2 {
      font-weight: 400;
      margin-top: 50px;
    }

    h3 {
      margin-top: 50px;
    }

    p {
      font-size: 15px;
    }

    pre {
      margin: 25px 0;
      padding: 20px;
      background-color: #f5f7f9;
      border: 1px solid #dee0e2;

      code {
        margin-top: -10px;
        display: block;

        &.html {
          > * {
            color: #2f6f9f;
            line-height: 20px;
          }

          [class*=hljs-tag] {
            color: #2f6f9f;
          }
          [class*=hljs-name] {
            color: #2f6f9f;
          }
          [class*=hljs-attr] {
            color: #4f9fcf;
          }
          [class*=hljs-string] {
            color: #d44950;
          }
        }
      }
    }

    ul {
      margin-bottom: 20px;

      li {
        margin: 5px 0;
      }
    }

    .panel {
      margin: 25px 0;

      .panel-footer {
        background-color: #f5f7f9;

        pre {
          padding: 0 10px;
          margin: 0;
          border: none;
        }
      }

      i {
        font-size: 15px;
      }
    }

    .btn-pill {
      outline: none;
      margin-right: 5px;
      font-weight: 500;

      &.btn-pill-danger:not(.btn-pill-sm), {
        margin-top: 7px;
      }
    }

    .btn-shadow {
      margin-right: 10px;
      outline: none;
    }

    .transparent-navbar-wrapper {
      position: relative;

      .ion-chevron-down {
        font-size: 10px;
      }

      .transparent-navbar-header {
        height: 190px;
      }
    }

    .slider-component {
      height: 450px;

      li {
        margin: 0;
      }

      h3 {
        color: #fff;
        text-align: center;
        position: absolute;
        top: calc(50% - 60px);
        left: 0;
        right: 0;
        font-size: 26px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
      }
    }

    .slider-options {
      padding: 0;

      li {
        display: inline-block;
        margin-right: 25px;

        a {
          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
