.DescargarDemo
{

  #thkimg
  {
    margin-top: 150px;
     @media(max-width: 768px) {
       margin-top: 20px;
     }

  }


  h4
  {
    margin-bottom: 3%; 
    color: #a0a0a0;
    font-size: 15px;
      margin-top: -20px;

  }
  h3
  {
    margin-bottom: 39px;
    font-size: 29px;
  }

  img 
  {
    max-height: 128px;

  }

  label
  {
    font-size: 16px;
  }

option {
  font-size: 17px;

}

.telefono
{
  padding-right: 30px; 
  padding-top: 20px; 
  font-size: 20px; 
  font-weight: 500; 
  color: rgb(255,35,71);

  @media(max-width: 768px) {
    display: none;
  }
}
.Position-Circle-1
{
  border-radius: 30px;
  background-color: #D9D9D9; 
  height: 35px; 
  width: 35px; 
  margin-right: 14px;
  padding-top: 7px; 
  color:#909090; 
  font-size: 16px;

    @media(max-width: 768px) {
    display: none;
  }
}
.circulo-cot
{
  border-radius: 30px;
  background-color: #D9D9D9; 
  height: 35px; 
  width: 35px; 
  margin-right: 5px;
  padding-top: 7px; 
  color:#909090; 
  font-size: 16px;
  display: inline-block;

}

.Position-Circle-2
{
  border-radius: 30px;
  background-color: #D9D9D9; 
  height: 35px; 
  width: 35px; 
  margin-left: -7px; 
  padding-top: 7px; 
  color:#909090; 
  font-size: 16px;
    @media(max-width: 768px) {
    display: none;
  }
}


.clients-form
{
  @media(max-width: 767px)
  {
    display: none;
  }
}

  .line
  {
    height: 1px;
    width: 500px;
    background-color: #b0b0b0;
    margin-bottom: 44px;

@media(max-width: 768px)
{
width: 100%;
}

  }

    .Form-Demo
  {

    width: 500px;

      @media(max-width: 768px) {

          width: 100%;
    }

      .Input-Demo
  {

          width: 500px; 
          height: 50px; 
          margin-bottom: 30px;
          -moz-border-radius: 8x;
          -webkit-border-radius: 8px;
          border-radius: 8px;
          border: 1px solid #D8E0E6;

           &:focus 
          {
            box-shadow: 2px 2px 5px  #505050;
            outline: none;
          }


        
      @media(max-width: 768px) {
        width: 100%;

    }

  }


  }

  .Input-Submit
  {
    margin-bottom: 10%;
    color: white;
    font-weight: 500;
    box-shadow: 2px 2px 5px  #505050;
    
  }

}