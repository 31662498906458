/* roboto font */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300italic,300,400italic,500,500italic,700,700italic,900,900italic);
/* source sans pro */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);
/* lato */
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);
/* merriweather */
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300italic,300,400italic,700,700italic);
/* open sans */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,600,700,800);
/* raleway */
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,300);
/* libre franklin */
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,700);
/* karla */
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

$roboto: "Roboto";
$lato: 'Lato', 'Helvetica Neue', Arial;
$helvetica: "Helvetica Neue", Helvetica, Arial;
$myriad: Myriad Pro, Lato, Helvetica Neue, Arial;
$sourceSans: Source Sans Pro, Helvetica Neue, Arial;
$merriweather: "Merriweather", Georgia;
$openSans: "Open Sans", Helvetica Neue, Arial;
$raleway: "Raleway", Helvetica Neue, Arial;
$georgia: Georgia, "Merriweather", Arial;
$franklin: 'Libre Franklin', 'Lato', 'Helvetica Neue', Arial;
$karla: 'Karla', 'Lato', 'Helvetica Neue', Arial;
